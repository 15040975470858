export const galleryPhotoData = (photos) => {

	return photos.map(photo => {
		const imageMeta = photo.childMarkdownRemark.frontmatter
		const imageData = photo.childMarkdownRemark.frontmatter.featuredImage.childImageSharp.gatsbyImageData
		return {
			src: imageData.images.fallback.src,
			srcSet: imageData.images.fallback.srcSet,
			sizes: imageData.images.fallback.sizes,
			height: imageData.height,
			width: imageData.width,
			title: imageMeta.title
		}
	})
}

export const prismicGalleryPhotoData = (photos) => {
	return photos.map(photo => {
		const image = photo.data.image
		return {
			src: image.gatsbyImageData.images.fallback.src,
			srcSet: image.gatsbyImageData.images.fallback.srcSet,
			sizes: image.gatsbyImageData.images.fallback.sizes,
			height: image.dimensions.height,
			width: image.dimensions.width,
			title: photo.data.title.text
		}
	})
}
