import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import PrismicPhotoGallery from '../components/Page/PrismicPhotoGallery'
import GalleryNav from '../components/Page/GalleryNav'
import { prismicGalleryPhotoData } from '../utils/galleryHelper'

const galleryPage = ({data}) => {

	const photos = prismicGalleryPhotoData(data.gallery.nodes)
	const year = Number(data.gallery.nodes[0].data.year)
	const years = []
	data.years.nodes.forEach(item => {
		const { year } = item.data
		if (year?.length && !years.includes(year)) {
			years.push(year)
		}
	})
	years.sort((a, b) => Number(b) - Number(a))

	return (
		<Layout>
			<section className="pt-12 pb-16 px-4 bg-boswell-handwriting">
				<h2 className="text-4xl mt-12 mb-4">{year} Festival Gallery</h2>
				<PrismicPhotoGallery photos={photos} showCredit={true}/>
				<GalleryNav year={year} years={years} />
			</section>
		</Layout>
	)
}

export const query = graphql`
	query($year: String!) {
		gallery: allPrismicFestivalPhotoGallery(filter:{ data: { year: { eq: $year }}}, sort : { fields: data___order, order: ASC }) {
			nodes {
				data {
					order
					year 
					title {
						text
					}
					description {
						text
					}
					image {
						dimensions {
							width
							height
						}
						gatsbyImageData
					}
				}
			}
		}	
		years: allPrismicFestivalPhotoGallery(sort : { fields: data___year, order: DESC }) {
			nodes {
				data {
					year 
				}
			}
		}
	}
`

export default galleryPage
