import React, { useState, useCallback } from "react"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"

const PrismicPhotoGallery = (props) => {
	const { photos, showCredit } = props

	const [currentImage, setCurrentImage] = useState(0)
	const [viewerIsOpen, setViewerIsOpen] = useState(false)

	const openLightbox = useCallback((event, { photo, index }) => {
		setCurrentImage(index)
		setViewerIsOpen(true)
	}, []);

	const closeLightbox = () => {
		setCurrentImage(0)
		setViewerIsOpen(false)
	}

	return (
		<div className="">
			<Gallery photos={photos} onClick={openLightbox} margin={8} />
			<ModalGateway>
				{viewerIsOpen ? (
					<Modal onClose={closeLightbox}>
						<Carousel
							currentIndex={currentImage}
							views={photos.map(photo => ({
								...photo,
								srcset: photo.srcSet,
								caption: photo.title
							}))}
						/>
					</Modal>
				) : null}
			</ModalGateway>
			{ showCredit ? (
				<p className={`text-lg mt-8`}>Images by Festival Photographer</p>
			) : ''}
		</div>
	)
}

export default PrismicPhotoGallery
