import { Link } from 'gatsby'
import * as React from 'react'

const GalleryNav = (props) => {
	const { year } = props
	const years = props.years || [2019, 2018, 2017, 2016, 2015, 2013, 2012, 2011]

	return (
		<div>
			<h2 className="text-4xl mt-12 mb-8">{props.heading || `More galleries`}</h2>
			<ul className={`flex items-center flex-wrap`}>
				{ years.map((item, index) => {
					return (
						<li className={`border-2 border-bbf-teal text-center mr-4 mb-4`} key={index}>
							{ item === year ? (
								<p className={`block p-4 text-2xl text-white bg-bbf-teal`}>{item}</p>
							) : (
								<Link className={`block p-4 text-2xl text-bbf-teal hover:bg-bbf-teal hover:text-white hover:shadow-md transition-all`} to={`/gallery/${item}`}>{item}</Link>
							)}
						</li>
					)
				})}
			</ul>
		</div>
	)
}

export default GalleryNav
